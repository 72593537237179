window.updateFacetContentFilter = updateFacetContentFilter;
window.setCategoryContentFilterType = setCategoryContentFilterType;

let filter_type = null;

function updateFacetContentFilter() {
    let elements = document.querySelectorAll('#js-active-search-filters a');
    let filters = [];
    elements.forEach(value => {
        let name = value.getAttribute('data-facet-name');
        filters.push(name);
    });

    let has_filter = true;

    if(filters.length === 0) {
        filters.push("0");
        has_filter = false;
    }

    console.log(filters);

    let show_when_filter_active = document.querySelectorAll('.show-when-filter');
    show_when_filter_active.forEach(value => value.style.display = has_filter ? "block": "none");

    let shownElements = 0;

    let filterable_elements = document.querySelectorAll(".filterable-element");
    filterable_elements.forEach(value => {
        let element_filters = value.getAttribute('data-filter').split(",");

        const filteredArray = element_filters.filter(value => filters.includes(value));

        if(filteredArray.length >= 1 && (filter_type == null || filter_type == value.getAttribute('data-type'))) {
            value.style.display = "block";
            shownElements++;
        }
        else {
            value.style.display = "none";
        }
    });

    let gridElement = document.querySelector(".grid-documents");
    gridElement.style.display = (shownElements === 0) ? "none" : "grid";

    let show_when_empty = document.querySelectorAll('.show-when-empty');
    show_when_empty.forEach(value => value.style.display = shownElements === 0 ? "block": "none");

    updateFacetContentCounter(has_filter, shownElements);
}

function setCategoryContentFilterType(type) {
    filter_type = type;

    // update visuelle
    let elements = document.querySelectorAll('.btn[data-when]');
    elements.forEach(value => {
        let attr = value.getAttribute('data-when');
        value.classList.remove("selected");
        if((filter_type == null && attr == "null") || attr == filter_type)
            value.classList.add("selected");
    })

    updateFacetContentFilter();
}

function updateFacetContentCounter(show, amountToShow) {
    let counter = document.querySelector(".document-counter");
    let element = document.querySelector(".document-counter span");

    counter.style.display = (show)  ? "block" : "none";
    element.innerText = amountToShow;
}

setTimeout(() => {
    updateFacetContentFilter();
}, 1)
